import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { Slices } from "../components/slices.component"
import { Card } from "../components/card.blog.component"
import { linkFragment } from "../link-resolver"
import { RichText } from "../components/common/rich-text.component"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { Pagination } from "../components/common/pagination.component"
import SEO from "../components/common/seo.component"

const Blog = ({ data, pageContext }) => {
  const page = data.prismic.allBlog_pages.edges[0]
  const categories = data.prismic.allBlog_categorys.edges
  const { posts, numPages, currentPage } = pageContext
  if (page) {
    return (
      <Layout>
        <SEO
          title={page.node.page_title || page.node.title}
          description={page.node.description || page.node.text}
          keywords={page.node.keywords}
          image={page.node.preview_image}
          lang={page.node._meta.lang}
        />
        <div className="container">
          <Header theme="light"/>
        </div>
        <div className="container">
          <nav className="nav blog-list-nav">
            <Link className="nav-link" activeClassName={"active"} to={"/blog"}>Last Posts</Link>
            {categories.map(item => {
              const category = item.node
              return (
                <Link className="nav-link" to={`/blog/${category._meta.uid}`}>
                  <PlainStructuredText structuredText={category.title}/>
                </Link>
              )
            })}
          </nav>
          <div className="my-5 mt-1">
            <RichText className="text-dark-blue" render={page.node.text_content}/>
          </div>
          <div className="container-blog-list mx-auto">
            <div className="row mb-5">
              {
                  posts? posts.map(item => {
                      return (
                        <Card item={item}/>
                        )
                    }
              ) : null}
            </div>
          </div>
        </div>
        <Pagination currentPage={currentPage} numPages={numPages} path={'/blog'}/>
        <Slices body={page.node.body}/>
      </Layout>
    )
  }
  return null
}

Blog.fragments = [linkFragment]
export default Blog

export const query = graphql`
    query blogQuery {
        prismic {
            allBlog_pages {
                edges {
                    node {
                        text_content
                        page_title
                        description
                        keywords {
                            keyword
                        }
                        preview_image
                        _meta {
                            uid
                            lang
                        }
                        body {
                            ... on PRISMIC_Blog_pageBodyGallery {
                                type
                                label
                                primary {
                                    anchor
                                    bgColor
                                    bgImage
                                    text
                                    title
                                }
                                fields {
                                    image
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_Blog_pageBodyPricing_plans {
                                type
                                label
                                fields {
                                    price_units
                                    plan_price
                                    plan_name
                                    plan_features
                                    link_text
                                    link_style
                                    link {
                                        ...link
                                    }
                                    is_free_plan
                                }
                                primary {
                                    bg_image
                                    bg_color
                                    title
                                    text
                                }
                            }
                            ... on PRISMIC_Blog_pageBodyText {
                                type
                                label
                                primary {
                                    text
                                    bg_color
                                    bg_image
                                }
                            }
                            ... on PRISMIC_Blog_pageBodyFeature {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                }
                                fields {
                                    image
                                    link_style
                                    link_text
                                    text
                                    title
                                    link {
                                        ...link
                                    }
                                }
                            }
                            ...on PRISMIC_Blog_pageBodyBlock_with_text_and_image {
                                label
                                type
                                primary  {
                                    bg_color
                                    bg_image
                                    min_height
                                    title
                                    text
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_Blog_pageBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    form_script
                                    form_url
                                    text
                                }
                            }
                        }
                    }
                }
            },
            allBlog_categorys {
                edges {
                    node {
                        title
                        _meta {
                            uid
                            type
                            lang
                        }
                    }
                }
            },
        }
    }
`
